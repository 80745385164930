import { render, staticRenderFns } from "./MyAccount.vue?vue&type=template&id=18b514c2&scoped=true&"
import script from "./MyAccount.vue?vue&type=script&lang=js&"
export * from "./MyAccount.vue?vue&type=script&lang=js&"
import style0 from "./MyAccount.vue?vue&type=style&index=0&id=18b514c2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18b514c2",
  null
  
)

export default component.exports